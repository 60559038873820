(function(){

	$.fn.inView = function(){
		//Window Object
		var win = $(window);
		//Object to Check
		obj = $(this);
		//the top Scroll Position in the page
		var scrollPosition = win.scrollTop();
		//the end of the visible area in the page, starting from the scroll position
		var visibleArea = win.scrollTop() + win.height();
		//the end of the object to check
		var objBeginPos = obj.offset().top;
		var objEndPos = (objBeginPos + obj.outerHeight());
		return(visibleArea >= objBeginPos ? true : false);
	};

	function getScrollY() {
		return (window.pageYOffset != null) ? window.pageYOffset : (document.documentElement.scrollTop != null) ? document.documentElement.scrollTop : document.body.scrollTop;
	}
	var cssTransform = (function(){
		var prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' ')
				, el = document.createElement('div')
				, cssTransform
				, i = 0
		while( cssTransform === undefined ){
			cssTransform = document.createElement('div').style[prefixes[i]] != undefined ? prefixes[i] : undefined
			i++
		}
		return cssTransform
	})();


	$(document).ready(function(){
		$('#scroll-to-content').on('click touchend', function(e){
			e.preventDefault();
			var contentTop = $('#content').offset().top - 96;
			TweenMax.to(window, 1, {scrollTo: {y: contentTop}});
		});

		$('[data-img]').each(function(){
			$('<img/>')[0].src = $(this).data('img');
		});

		$('.news-bg.not-loaded').each(function(){
			$(this).css('background-image',  'url(' + $(this).data('img') + ')');
			if($(this).inView()) {
				// TweenMax.to($(this), 0.6, {opacity: 1});
				$(this).removeClass('not-loaded');
			}
		});



		$(window).on('scroll touchmove gesturechange', function(){
			$('.teaser-box.not-loaded').each(function(){
				if($(this).inView()) {
					$(this).find('.tb-bg-img figure').css('background-image', 'url(' + $(this).find('.tb-bg-img figure').data('img') + ')');
					TweenMax.to($(this).find('.tb-bg-img figure'), 0.6, {opacity: 1});
					$(this).removeClass('not-loaded');
				}
			});


			$('.news-bg.not-loaded').each(function(){
				if($(this).inView()) {
					// TweenMax.to($(this), 0.6, {opacity: 1});
					$(this).removeClass('not-loaded');
				}
			});
			// var teaserBoxes = $('.tb-bg-img figure');
			// var scrollTop = getScrollY();
			// teaserBoxes.each(function(){
			// 	var elementPos = $(this).offset().top - $(window).height();
			// 	if(scrollTop > elementPos) {
			// 		elementScroller =  elementPos - scrollTop;
			// 	var endTop = elementScroller * -0.15;
			// 	// if(endTop > 0 && endTop < textArea.data('endtop')) {
			// 	if (cssTransform)
			// 		$(this)[0].style[cssTransform] = "matrix(1,0,0,1,0,-"+ endTop + ")";
			// 	else
			// 		$(this)[0].style["top"] = endTop;
			// 	}
			// });

		});


		$(window).on('scroll touchmove gesturechange', function(){
			

		
		});
	});


	$(window).on('load', function(){
		$('.teaser-box.not-loaded').each(function(){
			if($(this).inView()) {
				$(this).find('.tb-bg-img figure').css('background-image', 'url(' + $(this).find('.tb-bg-img figure').data('img') + ')');
				TweenMax.to($(this).find('.tb-bg-img figure'), 0.6, {opacity: 1});
				$(this).removeClass('not-loaded');
			}
		});
		$('.news-bg.not-loaded').each(function(){
			if($(this).inView()) {
				$(this).css('background-image',  'url(' + $(this).data('img') + ')');
				TweenMax.to($(this), 0.6, {opacity: 1});
				$(this).removeClass('not-loaded');
			}
		});
	});


})();