var Search;
Search = function () {
	'use strict';
	var self  = this;
	$('button[data-toggle=ercas-search]').on('click touchend',function(e){
		e.preventDefault();
		self.toggleSearch();
	});
	$('#close-search').on('click touchend',function(e){
		e.preventDefault();
		self.toggleSearch();
	});

	$('#search-main').bind('scroll', function() {
		//if($(this).scrollTop() + $(this).innerHeight()>=$(this)[0].scrollHeight) {
		//
		//}
		if($(this).scrollTop() === 0) {
			if($('#search-inner').hasClass('down')){
				$('#search-inner').removeClass('down');
			}
		} else {
			if(!$('#search-inner').hasClass('down')){
				$('#search-inner').addClass('down');
			}
		}
	});

	$('#to-top-search').on('click touchend', function(e) {
		e.preventDefault();
		TweenMax.to('#search-main', 1, {scrollTo: {y: 0}});
	});

};

Search.prototype.toggleSearch = function(){
	'use strict';
	var self = this;
	var nav = $('html');
	var bodyElement = $(document.body);
	if (nav.hasClass('search-open')) {
		nav.removeClass('search-open');
		$('[name="ercas-searchfield"]').blur();
		TweenMax.to($('#search'), 1, {width: 0});
		TweenMax.to($('#overlay-background'), 1,  {opacity: 0}).eventCallback("onComplete", function () {
			//$('#overlay-background').css('display', 'none');

		});
		$('html,body').attr('style', '');

		//this.changeMenuButton(0);
		bodyElement.scrollTop(window.scrollPos);
		window.scrollPos = 0;
		$('body').on('touchmove', 'body', function (e) {
			e.stopPropagation();
		});
	} else {
		window.scrollPos = bodyElement.scrollTop();

		//$('#overlay-background').css('z-index', 110);
		var SearchInnerWidth = $('#search').find('#search-inner').width() + 64;
		if($(window).width() < 768) {
			SearchInnerWidth = '100%';
		}
		TweenMax.to($('#overlay-background'),1, { ease: Power0.easeNone, display:'block', opacity: 1} );

		TweenMax.to($('#search'), 1, {width: SearchInnerWidth});
		nav.addClass('search-open');

		//self.changeMenuButton(1);
		$('html').height($(window).height()).css({overflow: 'hidden'});
		var newScrollPos = 0;
		if (window.scrollPos > $(window).height()) {
			newScrollPos = window.scrollPos + $(window).height() ;
		} else {
			newScrollPos = window.scrollPos;
		}
		bodyElement.height($(window).height()).css({overflowY: 'scroll'}).scrollTop(0);
		$('[name="ercas-searchfield"]').focus();
	}

};



// jQuery must be loaded!
if (typeof jQuery !== 'undefined') {
	$(document).ready(function () {
		'use strict';

		// INIT NAVIGATION
		new Search();
	});
} else {
	console.warn('jQuery is not available. Search.js can\'t be loaded.');
}
