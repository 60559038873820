(function(){
	var ercasSlideshow = function () {
		'use strict';

		var self = this;

		if($('#image-container figure').length == 1) {
			if ($('#text-area .active p').length == 0) {
				if (!$('#text-area').hasClass('not-visible')) {
					$('#text-area').addClass('not-visible');
				}
			} else {
				if ($('#text-area').hasClass('not-visible')) {
					$('#text-area').removeClass('not-visible');
				}
			}
		}

		//$('#image-container').on('click', function(e){
		//	e.preventDefault();
		//	self.changeSlide();
		//});
		//
		//$('#image-container figure').on('transitionend', function () {
		//	if($(this).hasClass('active') && !$(this).hasClass('right')) {
		//		$('#image-container figure.active.right').addClass('disableTransition').removeClass('active').removeClass('right').removeClass('disableTransition');
		//	}
		//});

		$('.es-indecators li').on('click touchend', function(e){
			e.preventDefault();
			if(!$(this).hasClass('active')) {
				self.changeSlide('right', $(this).data('slide'));
			}
		});
		$('.es-controls').on('click touchend', function(e){
			e.preventDefault();
			if($(this).hasClass('left')) {
				self.changeSlide('left');
			} else {
				self.changeSlide();
			}
		});

		// if($('#text-area').length > 0) {
		// 	$('#text-area').css('minHeight', $('#text-area')[0].offsetWidth);
		// }
	};

	ercasSlideshow.prototype.changeSlide = function (direction, slide) {
		'use strict';
		direction = direction || 'right';
		slide = slide || null;
		if(!$('#image-container').hasClass('active')) {
			$('#image-container').addClass('active');
			var curItem = $('#image-container figure.active');
			var curText = $('.image-description div.active');
			var nextText = curText.next('div');
			var nextItem = curItem.next('figure');



			if (!nextItem.is('figure')) {
				nextText = $('.image-description div').first();
				nextItem = $('#image-container figure').first();
			}

			if(direction === 'left'){
				nextText = curText.prev('div');
				nextItem = curItem.prev('figure');
				if (!nextItem.is('figure')) {
					nextText = $('.image-description div').last();
					nextItem = $('#image-container figure').last();
				}
			}

			if(slide) {
				nextText = $('.image-description div[data-slide="'+slide+'"]');
				nextItem = $('#image-container figure[data-slide="'+slide+'"]');
			} else {
				slide = nextItem.data('slide');
			}

			if(nextText === '') {
				if(!$('#text-area').hasClass('not-visible')) {
					$('#text-area').addClass('not-visible');
				}
			} else {
				if($('#text-area').hasClass('not-visible')) {
					$('#text-area').removeClass('not-visible');
				}
			}

			if(direction === 'left'){

				curItem.addClass('right');
				nextText.css({left: '100%'});
				nextItem.css({left: '100vw'});
				TweenMax.to($('.image-description'), 0.4, {height: nextText.height()});
				$('.es-indecators li.active').removeClass('active');
				$('.es-indecators li[data-slide="' + slide + '"]').addClass('active');
				TweenMax.to(curText, 0.7, {left: '-100%', opacity: '0'});
				TweenMax.to(nextText, 0.7, {left: '0', opacity: '1'});

				var TimeLine1 = TweenMax.to(curItem, 2, {left: '-100vw'});
				nextItem.addClass('active');
				nextText.addClass('active');
				TweenMax.to(nextItem, 1, {left: '0'}).eventCallback("onComplete", function () {
					TimeLine1.kill();
					curText.removeClass('active').css({left: '-100%'});
					curItem.removeClass('active right').css({left: '-100vw'});
					$('#image-container').removeClass('active');

				});
			} else {
				curItem.addClass('right');
				$('.es-indecators li.active').removeClass('active');
				$('.es-indecators li[data-slide="' + slide + '"]').addClass('active');
				TweenMax.to(curText, 0.7, {left: '100%', opacity: '0'});
				TweenMax.to(nextText, 0.7, {left: '0', opacity: '1'});
				TweenMax.to($('.image-description'), 0.4, {height: nextText.height()});
				var TimeLine1 = TweenMax.to(curItem, 2, {left: '100vw'});
				nextItem.addClass('active');
				nextText.addClass('active');
				TweenMax.to(nextItem, 1, {left: '0'}).eventCallback("onComplete", function () {
					TimeLine1.kill();
					curText.removeClass('active').css({left: '-100%'});
					curItem.removeClass('active right').css({left: '-100vw'});
					$('#image-container').removeClass('active');

				});
			}
		}

	};

	function getScrollY() {
		return (window.pageYOffset != null) ? window.pageYOffset : (document.documentElement.scrollTop != null) ? document.documentElement.scrollTop : document.body.scrollTop;
	}
	var cssTransform = (function(){
		var prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' ')
				, el = document.createElement('div')
				, cssTransform
				, i = 0
		while( cssTransform === undefined ){
			cssTransform = document.createElement('div').style[prefixes[i]] != undefined ? prefixes[i] : undefined
			i++
		}
		return cssTransform
	})();
	$(document).ready(function(){
		if($('#ercas-slideshow').length > 0) {
			new ercasSlideshow();

			$(window).on('scroll touchmove gesturechange', function () {
				var textArea = $('#image-container');
				var scrollTop = getScrollY();
				var endTop = scrollTop * -0.2;
				// if(endTop > 0 && endTop < textArea.data('endtop')) {
				if (cssTransform)
					textArea[0].style[cssTransform] = "matrix(1,0,0,1,0," + endTop + ")";
				else
					textArea[0].style["top"] = endTop;

				// }
				// if(endTop <= 0 ){
				//
				// 	if (cssTransform)
				// 	textArea[0].style[cssTransform] = "matrix(1,0,0,1,0,0)";
				// else
				// 	textArea[0].style["top"] = 0;
				//
				// }
				// if( endTop > textArea.data('endtop')) {
				// 	if (cssTransform)
				// 	textArea[0].style[cssTransform] = "matrix(1,0,0,1,0," + textArea.data('endtop') + ")";
				// 	else
				// 		textArea[0].style["top"] = textArea.data('endtop');
				// }
			});
		}
	});

	$(window).on('load', function(){
		if($('#ercas-slideshow').length > 0) {
			$('.image-description').height($('.image-description .active').height());
		}
	});

	// $(window).resize(function(){
	// 	$('#text-area').data('endtop',parseInt($('#text-area').css('top')) + parseInt($('#text-area').height()));
	// });


})();