//
// $('#element').donetyping(callback[, timeout=1000])
// Fires callback when a user has finished typing. This is determined by the time elapsed
// since the last keystroke and timeout parameter or the blur event--whichever comes first.
//   @callback: function to be called when even triggers
//   @timeout:  (default=1000) timeout, in ms, to to wait before triggering event if not
//              caused by blur.
// Requires jQuery 1.7+
//
;(function($){
	$.fn.extend({
					donetyping: function(callback,timeout){
						timeout = timeout || 1e3; // 1 second default timeout
						var timeoutReference,
								doneTyping = function(el){
									if (!timeoutReference) return;
									timeoutReference = null;
									callback.call(el);
								};
						return this.each(function(i,el){
							var $el = $(el);
							// Chrome Fix (Use keyup over keypress to detect backspace)
							// thank you @palerdot
							$el.is(':input') && $el.on('keyup keypress paste',function(e){
								// This catches the backspace button in chrome, but also prevents
								// the event from triggering too preemptively. Without this line,
								// using tab/shift+tab will make the focused element fire the callback.
								if (e.type=='keyup' && e.keyCode!=8) return;

								// Check if timeout has been set. If it has, "reset" the clock and
								// start over again.
								if (timeoutReference) clearTimeout(timeoutReference);
								timeoutReference = setTimeout(function(){
									// if we made it here, our timeout has elapsed. Fire the
									// callback
									doneTyping(el);
								}, timeout);
							}).on('blur',function(){
								// If we can, fire the event since we're leaving the field
								doneTyping(el);
							});
						});
					}
				});
})(jQuery);


var ercasSearch;
ercasSearch = function () {
	'use strict';

	var self = this;

	var minKeywordlength = $('[name="ercas-searchfield"]').data('min-length') ? $('[name="ercas-searchfield"]').data('min-length') : 3;
	var curLanguage = $('[name="ercas-searchfield"]').data('language') ? parseInt($('[name="ercas-searchfield"]').data('language')) : 0;
	var old_value = $('[name="ercas-searchfield"]').val();
	$('[name="ercas-searchfield"]').on('input' , function (e) {
		e.preventDefault();
		var new_value = $(this).val();
		if (new_value !== old_value) {
			old_value = new_value;
			if (!$('#spinner').hasClass('show')) {
				$('#spinner').addClass('show');
			}
		}
	});

	$('[name="ercas-searchfield"]').donetyping(function () {
		var searchString = $(this).val();
		self.checkInputSearch();
		if(minKeywordlength <= searchString.length) {
			if($('#search').hasClass('to-short')) {
				$('#search').removeClass('to-short');
			}
			$.post(window.location.href, {type: 5000, tx_kesearch_pi1: { sword:searchString }}, function (data) {


				$('#scroll-content').html($(data).find('#search #search-inner #search-main #scroll-content').html());

				var html ='';

				// var jsonElement = jQuery.parseJSON(data);
				// var counterObj = Object.keys(jsonElement).length;
				if($('#count-result').html() > 0) {
					if(typeof ga !== 'undefined') {
						ga('send', 'pageview', window.location.href + '?type=5000&tx_kesearch_pi1[sword]=' + searchString + '&q=' + searchString);
					}
					if($('#search').hasClass('no-results')) {
						$('#search').removeClass('no-results');
						$('#no-results-found').fadeOut();
					}
					// $.each(jsonElement, function (i, item) {
					// 	if(i < 10) {
					// 		html += '<article class="content" ><div class="inner-result"><h3><a href="' + item.link + '">' + item.title + '</a></h3><p>' + item.teaser + '</p><a href="' + item.link + '" class="more-link">' + item.linktext + '</a></div></article>';
					// 	} else {
					// 		html += '<article class="content show-on-scroll" ><div class="inner-result"><h3><a href="' + item.link + '">' + item.title + '</a></h3><p>' + item.teaser + '</p><a href="' + item.link + '" class="more-link">' + item.linktext + '</a></div></article>';
					// 	}
					// });
					// $('#count-result').html(counterObj);
					// $('#search-results').html(html);

					var $curentScrollPos = $('#search-main').scrollTop() + $('#search-main').innerHeight();
					$('#search-results article.show-on-scroll').each(function(i) {
						if($(this).offset().top < $curentScrollPos) {
							$(this).removeClass('show-on-scroll');
						}
					});

					if($('#spinner').hasClass('show')) {
						$('#spinner').removeClass('show');
					}

				} else {
					$('#search').addClass('no-results');
					$('#no-results-found').fadeIn();
					$('#count-result').html('0');
					$('#search-results').html('');
					if($('#spinner').hasClass('show')) {
						$('#spinner').removeClass('show');
					}
				}

				if(!$('#search').hasClass('results')) {
					$('#search').addClass('results');
				}
			});
			//console.log(searchString);
		} else {
			if($('#search').hasClass('no-results')) {
				$('#search').removeClass('no-results');
				$('#no-results-found').fadeOut();
			}
			if(!$('#search').hasClass('to-short')) {
				$('#search').addClass('to-short');
			}
			$('#count-result').html('0');
			$('#search-results').html('');
			if($('#spinner').hasClass('show')) {
				$('#spinner').removeClass('show');
			}
			if($('#search').hasClass('results')) {
				$('#search').removeClass('results');
			}
		}
	}, 500);

	$('.rm-val-icon').on('click touchend', function(e){
		e.preventDefault();
		self.searchClickRmButton();
	});

	$('#search form').on('submit', function (e) {
		e.preventDefault();
		$('[name="ercas-searchfield"]').trigger('input');
	});

	$('#search-main').on('scroll', function (e) {
		var $curentScrollPos = $(this).scrollTop() + $(this).innerHeight();
		$('#search-results article.show-on-scroll').each(function(i) {
			if($(this).offset().top < $curentScrollPos) {
				$(this).removeClass('show-on-scroll');
			}
		});
	});
};

/**
 * This function is checking the value of the search input field
 * and adds an remove button if a character is insert
 */
ercasSearch.prototype.checkInputSearch = function () {
	var searchInput = $('[name="ercas-searchfield"]');
	var rmIcon = $('.rm-val-icon');

	if (searchInput.attr('placeholder') != searchInput.val() && !rmIcon.hasClass('show') && searchInput.val() != '') {
		rmIcon.addClass('show');
	}
	if (searchInput.attr('placeholder') === searchInput.val() || searchInput.val() === '') {
		if (rmIcon.hasClass('show')) {
			rmIcon.removeClass('show');
		}
	}
};

/**
 * Function for clicking rm button of input
 */
ercasSearch.prototype.searchClickRmButton = function(){
	if($('#search').hasClass('results')) {
		$('#search').removeClass('results');
	}
	$('[name="ercas-searchfield"]').val('');

	$('.rm-val-icon').removeClass('show');

	setTimeout(function(){
		$("#search-results").html('');
		$('#count-result').html('0');
	}, 500);

	if($('#search').hasClass('no-results')) {
		$('#search').removeClass('no-results');
		$('#no-results-found').fadeOut();
	}

};


// jQuery must be loaded!
if (typeof jQuery !== 'undefined') {
	$(document).ready(function () {
		'use strict';

		// INIT NAVIGATION
		new ercasSearch();
	});
} else {
	console.warn('jQuery is not available. ercas-search.js can\'t be loaded.');
}

