ercasAddresses.init = function(){
	'use strict';
	var api = this;

	if($('[data-ea-onsubmit="true"]').length > 0) {
		api.onSubmit = true;
	}

	if($('[name="ea-country"]').length > 0) {
		$('[name="ea-zip"]').attr('readonly', true);
		$('[name="ea-zip"]').parents('form').addClass('no-zip');
	}


	// if result item is open load google maps
	$('#ea-results').on('show.bs.collapse', function (e) {
		var currentIFrame = $(e.target).closest('.panel').find('iframe');
		if (!currentIFrame.attr('src')) {
			currentIFrame.attr('src', currentIFrame.data("src"));
		}
	});

	if(!api.onSubmit) {
		var option = JSON.parse($('#ea-option-json').html());
		$('[name="ea-zip"]').parents('form').on('submit', function (e) {
			e.preventDefault();
			api.submitAjaxForm();
		});

		// $('[name="ea-zip"]').donetyping(function () {
		// 	api.submitAjaxForm();
		// });
		//
		// $('[name="ea-rage"], [name="ea-category"]').change(function () {
		// 	api.submitAjaxForm();
		// });
		$('[name="ea-country"]').change(function (e) {
			e.preventDefault();
			var value = parseInt($(this).val());
			$('[name="ea-zip"]').val('');
			$('#ea-count').html('0').parent().addClass('hide');
			$('#ea-results').html('');

			if(api.zipSearchAllowed(option.zipSearchAllow,value)) {
				$('[name="ea-zip"]').attr('readonly',false);
				$('[name="ea-zip"]').attr('required',true);
				if($('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
					$('[name="ea-zip"]').parents('form').removeClass('no-zip');
				}
			} else {
				$('[name="ea-zip"]').attr('readonly',true);
				$('[name="ea-zip"]').attr('required',false);
				if(!$('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
					$('[name="ea-zip"]').parents('form').addClass('no-zip');
				}
				api.submitAjaxForm();
			}

		});
	} else {
		// $('[name="ea-category"]').change(function (e) {
		// 	e.preventDefault();
		//
		// 	// if($(this).val() === '12') {
		// 	// 	$('[name="ea-country"]').val('54').trigger('change');
		// 	// }
		// });

		var option = JSON.parse($('#ea-option-json').html());
		$('[name="ea-country"]').change(function (e) {
			e.preventDefault();
			$('[name="ea-zip"]').val('');

			if ($('[name="ea-country"]').length > 0) {
				var country = $('[name="ea-country"]').val();
			} else {
				var country = '*';
			}
			if(api.zipSearchAllowed(option.zipSearchAllow,parseInt(country))) {
				$('[name="ea-zip"]').attr('readonly',false);
				$('[name="ea-zip"]').attr('required',true);
				if($('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
					$('[name="ea-zip"]').parents('form').removeClass('no-zip');
				}
			} else {
				$('[name="ea-zip"]').attr('readonly',true);
				$('[name="ea-zip"]').attr('required',false);
				if(!$('[name="ea-zip"]').parents('form').hasClass('no-zip')) {
					$('[name="ea-zip"]').parents('form').addClass('no-zip');
				}
			}
		});

		$('[data-ea-onsubmit="true"]').validator().on('submit', function (e) {
			e.preventDefault();
			// console.log('test');
			if ($('[name="ea-category"]').length > 0) {
				var category = $('[name="ea-category"]').val();
			} else {
				var category = '*';
			}
			if ($('[name="ea-country"]').length > 0) {
				var country = $('[name="ea-country"]').val();
			} else {
				var country = '*';
			}
			// console.log($(this).find('.has-error').length);
			if($(this).find('.has-error').length == 0) {
				if (!$(this).hasClass('no-zip') && $('[name="ea-zip"]').val() !== "") {
					api.searchByZip($('[name="ea-zip"]').val(), $('[name="ea-rage"]').val(), category, country);
				}  else {
					if ($(this).hasClass('no-zip')) {
						api.searchByZip($('[name="ea-zip"]').val(), $('[name="ea-rage"]').val(), category, country);
					}
				}
			} else {
				// console.log($(this).find('.has-error').length);
			}

		});
	}

	$('.rm-ea-val-icon').on('click touchend', function(e){
		e.preventDefault();
		api.searchClickRmButton();
	});
};

ercasAddresses.validateForm = function() {
	'use strict';
	
	var error = false;
	// $('#addresses-form').validator().on('submit', function (e) {
	// 	if (e.isDefaultPrevented()) {
	// 		// handle the invalid form...
	// 		error = true;
	// 	} else {
	// 		// everything looks good!
	// 	}
	// });
	// $('[name="ea-country"], [name="ea-category"]').each(function() {
	// 	if($(this).attr('required')) {
	// 		var parentWrap = $(this).parents('.form-group').first();
	// 		if($(this).val() == '*') {
	// 			error = true;
	// 			if(!parentWrap.hasClass('error')) {
	// 				parentWrap.addClass('error');
	// 			}
	// 		} else {
	// 			if(parentWrap.hasClass('error')) {
	// 				parentWrap.removeClass('error');
	// 			}
	// 		}
	// 	}
	// });

	// console.log(error);

	if (error) {
		return false;
	} else {
		return true;
	}
};


ercasAddresses.showAddresses = function (ajaxData) {
	'use strict';
	$('#ea-results').html('');
	var counterResults = 0;

	$.each(ajaxData, function (key, value) {
		$('#ea-results').append(value.html);
		counterResults++;
	});

	if(counterResults > 0) {
		if(!$('#error-address').hasClass('hidden')) {
			$('#error-address').addClass('hidden');
		}
	} else {
		if($('#error-address').hasClass('hidden')) {
			$('#error-address').removeClass('hidden');
		}
	}

	// if($('#error-address').length > 0) {
	// 	$('#ea-count').html(0);
	// } else {
		$('#ea-count').html(counterResults);
	// }
	if(counterResults > 0 && $('#ea-results-counter').hasClass('hide')) {
		$('#ea-results-counter').removeClass('hide');
	} else {
		if(!$('#ea-results-counter').hasClass('hide') && counterResults == 0)
			$('#ea-results-counter').addClass('hide');
	}

	if($('#ea-referrer').length > 0) {
		$('#ea-referrer').val(document.referrer);
	}
	if(typeof TweenMax !== 'undefined') {
		TweenMax.to($('#PageLoader'), 1, {opacity: 0}).eventCallback("onComplete", function () {
			$('#PageLoader').css('z-index', '-1');
		});
	} else {
		this.toggleSpinner(false);
	}


};

ercasAddresses.submitAjaxForm = function() {
	'use strict';

	if ($('[name="ea-category"]').length > 0) {
		var category = $('[name="ea-category"]').val();
	}
	if ($('[name="ea-country"]').length > 0) {
		var country = $('[name="ea-country"]').val();
	}
	if(country == '*') {
		$('[name="ea-zip"]').val('');
		$('#ea-count').html('0').parent().addClass('hide');
		$('#ea-results').html('');
		return false;
	}
	if ($('[name="ea-zip"]').length > 0) {
		var zip = $('[name="ea-zip"]').val();
	}
	if ($('[name="ea-rage"]').length > 0) {
		var range = $('[name="ea-rage"]').val();
	}

	this.searchByZip(zip, range, category, country);

};