/**
 * Created by bauermar on 08.12.15.
 */

var Navigation;

Navigation = function () {
	'use strict';
	var self = this;
	window.changeSlide = 0;
	$('.subpage-selector').on('click',function(e){
		e.preventDefault();
		if(window.changeSlide == 0) {
			window.changeSlide = 1;
			// DEFINE VARIABLES
			var selector = $(this);
			var pageUid = selector.data('page-uid');

			// CALL CHANGE PAGE FUNCTION
			self.changePage(pageUid, function(){
				window.changeSlide = 0;
			});

		}
	});

	$('.return-page').on('click',function(e){
		e.preventDefault();

		// DEFINE VARIABLES
		var selector = $(this);
		var parentSelector = selector.parent();
		var parentPage = selector.data('return-pid');

		// SWITCH TO PARENT PAGE
		parentSelector.removeClass('show');
		$('.navigation-page[data-parent-pid='+parentPage+']').removeClass('right');

		$('#main-navigation').height($('.navigation-page[data-parent-pid='+parentPage+']').height());

	});

	$('button[data-toggle=ercas-navigation]').on('click touchend',function(e){
		e.preventDefault();
		self.toggleNavigation();
	});

	var curOpenSlider = $('.navigation-page[class="navigation-page show"]');
	$('#main-navigation').height(curOpenSlider.outerHeight());


	var Scrollable = '.scrollable';
	$(document).on('touchmove', function(e) {
		if($('html').hasClass('navigation-open')) {
			e.preventDefault();
		}
	});

	$('body').on('touchstart', Scrollable, function(e) {
		if (e.currentTarget.scrollTop === 0) {
			e.currentTarget.scrollTop = 1;
		} else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
			e.currentTarget.scrollTop -= 1;
		}
	});

	$('body').on('touchmove', Scrollable, function(e) {
		// Only block default if internal div contents are large enough to scroll
		// Warning: scrollHeight support is not universal. (http://stackoverflow.com/a/15033226/40352)
		if($(this)[0].scrollHeight > $(this).innerHeight()) {
			e.stopPropagation();
		}


	});

 //Stops preventDefault from being called on document if it sees a scrollable div
	//$('body').on('touchmove', Scrollable, function(e) {
	//	e.stopPropagation();
	//});

};

Navigation.prototype.toggleNavigation = function(){
	'use strict';
	var self = this;
	var nav = $('html');
	var bodyElement = $(document.body);

	if(nav.hasClass('search-open')) {
		$('#search-button').trigger('click');
	}

	if($('#close-search-detail').length > 0) {
		$('#close-search-detail').parent().trigger('click');
	} else {
		if (nav.hasClass('navigation-open')) {
			TweenMax.to($('#navigations '), 0.6, {width: 0});
			TweenMax.to($('#overlay-background'), 1,  {opacity: 0}).eventCallback("onComplete", function () {
				nav.removeClass('navigation-open');
			});

			$('html,body').attr('style', '');
			//this.changeMenuButton(0);
			bodyElement.scrollTop(window.scrollPos);
			window.scrollPos = 0;
			$('body').on('touchmove', 'body', function (e) {
				e.stopPropagation();
			});
		} else {
			window.scrollPos = document.body.scrollTop;
			var NavInnerWidth = $('#navigations ').find('#main-navigation').width();
			if($(window).width() < 768) {
				NavInnerWidth = '100%';
			}
			TweenMax.to($('#overlay-background'),1, { ease: Power0.easeNone, display:'block', opacity: 1} );
			TweenMax.to($('#navigations '), 0.6, {width: NavInnerWidth});
				nav.addClass('navigation-open');

				$('html').height($(window).height()).css({overflow: 'hidden'});
				var newScrollPos = 0;
				if (window.scrollPos > $(window).height()) {
					newScrollPos = window.scrollPos  ;
				} else {
					newScrollPos = window.scrollPos;
				}
				document.documentElement.scrollTop = document.body.scrollTop = newScrollPos;
				bodyElement.height($(window).height());
		}
	}
};

Navigation.prototype.changePage = function (pageUid, callback) {
	'use strict';
	// DEFINE VARIABLES
	var sliderToOpen = $('.navigation-page[data-parent-pid="'+pageUid+'"]');
	var curOpenSlider = $('.navigation-page[class="navigation-page show"]');

	if(!sliderToOpen.hasClass('show')) {
		// CALL FUNCTIONS
		curOpenSlider.addClass('right');
		sliderToOpen.addClass('show');
		$('#main-navigation').height(sliderToOpen.height());

		var naviDiv = document.getElementById('scroll-navigation');
		naviDiv.scrollTop = 0;
	}
	callback();
};

Navigation.prototype.changeMenuButton = function (state) {
	'use strict';
	var speed = 300;
	var o = Snap('button[data-toggle=ercas-navigation] object#menu-icon');
	var c = $('button[data-toggle=ercas-navigation] object#close-icon');
	var lineOne = o.select('#line-1');
	var lineTwo = o.select('#line-2');
	var lineThree = o.select('#line-3');
	var bbox = lineThree.getBBox();
	if(state == 1) {
		lineOne.animate({ transform:'t0,14' , opacity:'0'}, speed);
		lineThree.animate({ opacity:'0' }, speed);
		lineTwo.animate({ transform:'t0,-14', opacity:'0' }, speed);
		bbox = lineThree.getBBox();
		c.animate({ opacity:'1' }, speed);
		setTimeout(function(){
			//lineOne.animate({ transform:'r45,'+ bbox.cx + ',' + bbox.cy+',t14,14', width: 12, height:2 }, speed);
			//lineThree.animate({ opacity:'0' }, 600);
			//lineOne.animate({ opacity:'0' }, speed);
			//lineThree.animate({ opacity:'0' }, speed);
			//lineTwo.animate({ opacity:'0' }, speed);

			//lineTwo.animate({ transform:'r-45,'+ bbox.cx + ',' + bbox.cy+',t14,-14', width: 12, height:2 }, speed);
		},speed  );

	} else {
		//lineOne.animate({ transform:'r0,'+ bbox.cx + ',' + bbox.cy+',t0,14', width: 40, height:4 }, speed);
		//lineThree.animate({ opacity:'0' }, 600);
		//lineTwo.animate({ transform:'r0,'+ bbox.cx + ',' + bbox.cy+',t0,-14', width: 40, height:4 }, speed);
		c.animate({ opacity:'0' }, 100);
		setTimeout(function(){
			lineOne.animate({ transform:'' }, speed);
			lineOne.animate({opacity:'1' }, speed);
			lineTwo.animate({ opacity:'1' }, speed);
			lineThree.animate({ opacity:'1' }, speed);
			lineTwo.animate({ transform:'' }, speed);
		},speed);
	}
};

// jQuery must be loaded!
if (typeof jQuery !== 'undefined') {
	$(document).ready(function () {
		'use strict';

		// INIT NAVIGATION
		new Navigation();
	});
} else {
	console.warn('jQuery is not available. Navigation.js can\'t be loaded.');
}

